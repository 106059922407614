<template>
    <div class="scroll-item">
        <div class="image-text-container">
            <img :src=speciality.image alt="Dinao" class="image"/>
            <p class="text">{{ localizedNameText }}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SpecialityView',
    props: {
            speciality: {
                _id: String,
                name: String,
                image: String,
                name_ru: String
            }
    },
    computed: {
        localizedNameText() {
            switch (this.$i18n.locale) {
                case 'ru':
                    return this.speciality.name_ru
                default:
                    return this.speciality.name
            }
        }
    }
}
</script>

<style scoped>
.scroll-item {
  display: inline-block;
}
.image-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #F5F5F5;
    margin-left: 5px;
    margin-right: 5px;
    width: 95px;
    height: 65px;
    border-radius: 8px;
}
.image {
    padding: 0px;
    margin-top: 7px;
    height: 26px;
    width: 26px;
    margin-bottom: 1px;
}
.text {
    display: flex;
    justify-content: center;
    align-items: center;  
    padding: 0px;
    margin: 0px;
    width: 85px;
    height: 25px;
    color: black;
    font-size: 12px;
    white-space: normal; 
}
</style>