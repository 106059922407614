<template>
    <h2>{{ $t('components.place.photosSection.title') }}</h2>
    <div class="photo-gallery">
        <div class="row">
        <div v-for="(photo, index) in photos.slice(0, 2)" :key="index" class="photo-item">
            <img :src="photo" class="photo">
        </div>
        </div>
        <div class="row">
        <div v-for="(photo, index) in photos.slice(2, 5)" :key="index" class="photo-item">
            <img :src="photo" class="photo">
            <div 
                v-if="index == 2 && additionalPhotoCount !== 0"
                class="overlay"
                @click="openAppStore"
            >
            +{{ additionalPhotoCount }}
            </div>
        </div>
        </div>
    </div>
    <hr>
    <DownloadAppPage ref="popover"/>
</template>

<script>
import { computed } from 'vue';
import { logAnalyticsEvent } from '@/utils/firebase';
import DownloadAppPage from '@/pages/DownloadAppPage.vue';

export default {
    name: 'PhotosSectionView',
    components: {
        DownloadAppPage,
    },
    props: {
        photos: {
            type: Array,
            required: true
        }
    },
    setup(props) {
        const additionalPhotoCount = computed(() => props.photos.length - 5);
        return {
            additionalPhotoCount
        };
    },
    methods: {
        openAppStore() {
            logAnalyticsEvent('place_plus_photo_click');
            this.$refs.popover.openPopover();
        }
    }
}
</script>

<style scoped>
h2 {
    color: black;
    text-align: left;
    padding-left: 16px;
}
hr {
    border: none;
    height: 1px;
    background-color: #E6E6E7;
    margin: 20px 0;  /* Space above and below the line */
}
.photo-gallery {
    padding-left: 12px;
    padding-right: 12px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.row {
  display: flex;
  gap: 10px;
}
.photo-item {
  position: relative;
  flex: 1;
  overflow: hidden;
  border-radius: 8px;
  height: 95px;
}
.row:first-child .photo-item {
  flex: 1 1 50%;
}
.row:last-child .photo-item {
  flex: 1 1 33.33%;
}
.photo {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the entire area without stretching */
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  /* border-radius: 8px; */
  cursor: pointer;
}
</style>