<template>
    <button class="button">{{ title }}</button>
</template>
  
<script>
export default {
    name: 'ActionButton',
    props: {
        title: {
            type: String,
            default: 'Save'
        }
    }
};
</script>
  
<style scoped>
.button {
    background-color: #5F8C3C;
    border: none;
    color: white;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    padding: 10px 16px;
    border-radius: 12px;
    min-width: 350px;
    cursor: pointer;
}
</style>