<template>
    <NotFoundView :tapHandler="showMain"/>
 </template>
   
<script>  
import NotFoundView from '@/components/NotFound/NotFoundView.vue'; 
import { configureMetaData } from '@/utils/metaConfigurator';

export default {
    name: 'NotFoundPage',
    components: {
        NotFoundView
    },
    props: {
        redirectedFrom: {
            type: String
        }
    },
    methods: {
        showMain() {
            this.$router.push({ path: '/' })
        }
    },
    created() {
        this.id = this.$route.id
        configureMetaData(
            this.$t('components.notFound.title'),
            this.$t('components.notFound.text'),
            '../../assets/NotFoundView.svg'
        )
    },
    data() {
        return {
            id: null,
        }
    },
}
</script>
   
<style>
 
</style>