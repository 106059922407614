<template>
    <h2>{{ $t('components.place.interactiveMenuSection.title') }}</h2>
    <div class="tabs scroll-container">
      <span
        v-for="(category, index) in interactiveMenu"
        :key="index"
        @click="activeCategoryIndex = index"
        :class="{ active: activeCategoryIndex === index }"
      >
        {{ category.category }}
      </span>
    </div>
    <div class="menu-items scroll-container">
      <div
        v-for="(item, index) in filteredItems"
        :key="index"
        class="menu-item"
      >
        <InteractiveMenuItemView :item='item'/>
      </div>
    </div>
    <hr>
</template>

<script>

import InteractiveMenuItemView from './InteractiveMenuItemView.vue'

export default {
  name: 'InteractiveMenuSectionView',
  props: {
    interactiveMenu: Array
  },
  components: {
    InteractiveMenuItemView,
  },
  computed: {
    filteredItems() {
      let category = this.interactiveMenu[this.activeCategoryIndex].items;
      // console.log('menu:' + JSON.stringify(category))
      return category
    }
  },
  data() {
    return {
      activeCategoryIndex: 0, 
    }
  },
}
</script>

<style scoped>
h2 {
    color: black;
    text-align: left;
    padding-left: 16px;
    padding-top: 8px;
    padding-bottom: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
}
.scroll-container {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  padding-left: 4px;
  padding-top: 16px;
  padding-right: 4px;
  padding-bottom: 16px;
  scrollbar-width: none; /* for Firefox */
}
.scroll-container::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, etc. */
}
.tabs {
  display: flex;
  gap: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 0px;
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 0px;
}
.tabs span {
  cursor: pointer;
  padding-left: 8px;
  padding-right: 8px;
  color: #A6A6A6;
}
.tabs span.active {
  font-weight: bold;
  text-decoration: underline;
  color: #BE2827
}
.menu-items {
  display: flex;
  flex-wrap: nowrap;
  margin-left: 8px;
  margin-right: 8px;
  padding-top: 0px;
  padding-bottom: 0px;
}
.menu-item {
  margin-top: 10px;
  padding-top: 0px;
  text-align: center;
  display: inline-block;
  margin-left: 8px;
  margin-right: 8px;
  flex: 0 0 auto;
  border-radius: 12px;
  width: 270px;
  height: 250px;
  border: 1px solid #E6E6E7;
  overflow: hidden;
}
.menu-item img {
  max-width:100%;
  max-height:100%;
  object-fit: cover;
  object-position: center;
}
.menu-item h3 {
  margin: 10px 0;
  text-align: left;
  padding-top: 10px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 0px;
  margin: 0px;
  font-size: 16px;
  font-weight: bold;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2; 
  -webkit-box-orient: vertical;
}
.menu-item p {
  color: #555;
  text-align: left;
  padding-top: 2px;
  padding-right: 8px;
  padding-left: 8px;
  padding-bottom: 16px;
  margin: 0px;
  font-size: 12px;
}
hr {
    border: none;
    height: 1px;
    background-color: #E6E6E7;
}
</style>