<template>
    <div :style="backgroundImageStyle">
        <div class="main-container">
            <h1>{{ name }}</h1>
            <div class="center-container">
                <CategoryView v-for="category in categories" :key="category._id" :category="category" />
            </div>
            <div class="center-container">
                <AddressView :address='address'/>
            </div>
        </div>
    </div>
    <AboutView :about='about'/>
</template>

<script>
    import CategoryView from './CategoryView.vue'
    import AddressView from './AddressView.vue'
    import AboutView from './AboutView.vue'

    export default {
        name: 'MainImageSectionView',
        components: {
            CategoryView,
            AddressView,
            AboutView
        },
        props: {
            name: String,
            imageUrl: String,
            categories: [
                {
                    _id: String,
                    name: String,
                    icon: String
                }
            ],
            address: String,
            about: String,
        },
        data() {
            return {
                placeHolderUrl: require('@/assets/placeholder.png'),
                imageLoadFailed: false
            };
        },
        computed: {
            backgroundImageStyle() {
                if (this.imageLoadFailed) {
                    return this.failedBackgroundImageStyle
                } else {
                    return this.loadedBackgroundImageStyle
                }
            },
            loadedBackgroundImageStyle() {
                return {
                    backgroundImage: `url(${this.imageUrl})`,
                    'background-size': 'cover',
                    'background-position': 'center'
                }
            },
            failedBackgroundImageStyle() {
                return {
                    backgroundImage: `url(${this.placeHolderUrl})`,
                    'background-size': 'cover',
                    'background-position': 'center'
                }
            }
        },
        methods: {
            onImageError() {
                this.imageLoadFailed = true;
            }
        },
        mounted() {
            const img = new Image();
            img.src = this.imageUrl;
            img.onload = () => {
                this.imageLoadFailed = false;
            };
            img.onerror = this.onImageError;
        }
    }
</script>

<style scoped>
h1 {
    color: white;
    font-weight: bold;
    font-size: 22px;
    margin: 0px;
}
.main-container {
    display: flex; 
    flex-direction: column; 
    justify-content: flex-end;
    height: 378px; 
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 100%);
}
.center-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
</style>