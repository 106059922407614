<template>
    <div class="image-container">
        <img 
            :src='item.image_url'
            @error='onImageError'
            :alt='item.name'
        />
        </div>
        <h3 class="one-line">{{ item.name }}</h3>
        <div class="two-lines">
        <p>{{ item.description }}</p>
    </div>
</template>

<script>
export default {
    name: 'InteractiveMenuItemView',
    props: {
        item: {
            image_url: String,
            name: String,
            description: String
        }
    },
    data() {
        return {
            placeholderUrl: require('@/assets/menuMealPlaceholder.svg'),
            hasError: false 
        }
    },
    methods: {
        onImageError(event) {
            if (!this.hasError) {
              this.hasError = true
              event.target.src = this.placeholderUrl
            }
        }
    }
}
</script>

<style scoped>
.image-container {
    width: 270px;
    height: 167px;
}
.menu-item img {
  max-width:100%;
  max-height:100%;
  object-fit: cover;
  object-position: center;
}
.menu-item h3 {
  margin: 10px 0;
  text-align: left;
  padding-top: 10px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 0px;
  margin: 0px;
  font-size: 16px;
  font-weight: bold;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2; 
  -webkit-box-orient: vertical;
}
.menu-item p {
  color: #555;
  text-align: left;
  padding-top: 2px;
  padding-right: 8px;
  padding-left: 8px;
  padding-bottom: 16px;
  margin: 0px;
  font-size: 12px;
}
.one-line {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    line-height: 1.5em;
    max-height: 1.5em;
}
.two-lines {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  line-height: 1.2em;
  max-height: 2.4em;
}
</style>