<template>
    <div class="image-text-container">
        <img src = "../../../assets/locationIcon.svg" alt="Location" class="image"/>
        <p class="text">{{ address }}</p>
    </div>
</template>

<script>
    export default {
        name: 'AddressView',
        props: {
            address: String
        }
    }
</script>

<style scoped>
.image-text-container {
    display: flex;
    align-items: center;
    padding-top: 0px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
}
.image {
    height: 20px;
    width: 20px;
    margin-right: 4px;
}
.text {
    color: white;
    font-size: 15px;
}
</style>