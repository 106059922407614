<template>
    <div class="app-info">
        <img src="@/assets/appIcon.png" alt="AppStore Icon" class="app-icon">
        <div class="app-details">
            <div class="app-store-row">
                <img src="@/assets/appStoreIcon.svg" alt="AppStore Icon" class="app-store-icon">
                <p class="app-store-name">App Store</p>
            </div>
            <p class="app-name">{{ $t('components.downloadApp.appStore.appName') }}</p>
            <p class="app-description">{{ $t('components.downloadApp.appStore.appText') }}</p>
        </div>
   </div>
</template>
 
<script>
export default {
    name: 'AppStoreView',
};
</script>
 
<style scoped>
.app-info {
    display: flex;
    align-items: center;
}
.app-icon {
    width: 56px;
    height: 56px;
    margin-right: 10px;
}
.app-store-row {
    display: flex;
    align-items: center;
}
.app-store-icon {
    width: 12px;
    height: 11px;
    margin-right: 3px;
}
.app-store-name {
    color: #808085;
    font-size: 12px;
    font-weight: 500;
    margin: 0;
}
.app-details {
    text-align: left;
}
.app-name {
    font-size: 13px;
    color: black;
    font-weight: 500;
    margin: 0;
    padding-top: 3px;
}
.app-description {
    font-size: 11px;
    color: #808085;
    margin: 0;
    padding-top: 3px;
}
</style>