<template>
    <div class="footer">
        <p class="text">{{ $t('components.shared.FooterView.text') }}</p>
        <img 
            src = "../../assets/appStore.svg"
            width="145"
            height="50"
            alt="Download on the AppStore"
            @click="openAppStore"
        />
    </div>
</template>

<script>
import { logAnalyticsEvent } from '@/utils/firebase';

export default {
    name: 'FooterView',
    methods: {
        openAppStore() {
            logAnalyticsEvent('footer_appstore_click');
            window.open('https://apps.apple.com/ru/app/id6451155745', '_blank');
        }
    }
}
</script>

<style scoped>
.footer {
    background: #FFAE2E;
    padding-top: 18px;
    padding-bottom: 18px;
}
.text {
    margin-top: 0px;
    margin-left: 14px;
    margin-right: 14px;
    margin-bottom: 18px;
    padding: 0px;
    color: black;
    font-weight: bold;
    font-size: '16px';
}
img {
    cursor: pointer;
}
</style>