<template>
    <transition name="popover-background-transition">
        <div v-if="visible" class="popover-container-dark"></div> 
    </transition>
    <transition name="popover-card-transition">
        <div v-if="visible" class="popover-container" @click.self="closePopover">
            <div class="popover-background">
                <div class="popover-content">
                    <h1>{{ $t('components.downloadApp.title') }}</h1>
                    <AppStoreView/>
                    <p>{{ $t('components.downloadApp.about') }}</p>
                    <div class="actions">
                        <button class="cancel-button" @click="closePopover">{{ $t('components.downloadApp.notNowButtonTitle') }}</button>
                        <button class="download-button" @click="openAppStore">{{ $t('components.downloadApp.downloadButtonTitle') }}</button>
                    </div>
                </div>
            </div>
        </div> 
    </transition>
</template>
  
<script>
import AppStoreView from '@/components/DownloadApp/AppStoreView.vue';
import { logAnalyticsEvent } from '@/utils/firebase';

export default {
    components: {
        AppStoreView,
    },
    data() {
        return {
            visible: false,
        };
    },
    methods: {
        openPopover() {
            this.visible = true;
        },
        closePopover() {
            this.visible = false;
        },
        openAppStore() {
            window.open('https://apps.apple.com/ru/app/id6451155745', '_blank');
            logAnalyticsEvent('download_app_popup_appstore_click');
            this.closePopover()
        }
    },
};
</script>
  
<style scoped>
.popover-container {
    z-index: 999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.popover-container-dark {
    z-index: 999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}
.popover-background {
    z-index: 1000;
    background-color: white;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    width: 100%;
    position: fixed;
    bottom: 0;
}
.popover-content {
    padding: 16px;
    text-align: center;
}
h1 {
    color: black;
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 20px;
}
p {
    color: black;
    font-size: 16px;
    text-align: left;
    padding-top: 20px;
    padding-bottom: 0px;
}
.actions {
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.cancel-button {
    height: 40px;
    font-size: 16px;
    padding-left: 0px;
    background: none;
    border: none;
    color: #808085;
    cursor: pointer;
}
.download-button {
    height: 40px;
    font-size: 16px;
    padding-right: 0px;
    background: none;
    color: #BE2827;
    border: none;    
    cursor: pointer;
}
.popover-background-transition-enter-active,
.popover-background-transition-leave-active {
  transition: opacity 0.3s ease;
}
.popover-background-transition-enter-from {
  opacity: 0;
}

.popover-background-transition-enter-to {
  opacity: 1;
}

.popover-background-transition-leave-from {
  opacity: 1;
}

.popover-background-transition-leave-to {
  opacity: 0;
}

.popover-card-transition-enter-active,
.popover-card-transition-leave-active {
  transition: transform 0.3s ease;
}
.popover-card-transition-enter-from {
  transform: translateY(100%);
}

.popover-card-transition-enter-to {
  transform: translateY(0);
}

.popover-card-transition-leave-from {
  transform: translateY(0);
}

.popover-card-transition-leave-to {
  transform: translateY(100%);
}
</style>