<template>
    <div class="full-space" v-if='about !== null'>
        <p class="about-text">{{ about }}</p>
        <!-- <p class="about-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam consectetur venenatis blandit. Praesent vehicula, libero non pretium vulputate, lacus arcu facilisis lectus, sed feugiat tellus nulla eu dolor. Nulla porta bibendum lectus quis euismod. Aliquam volutpat ultricies porttitor. Cras risus nisi, accumsan vel cursus ut, sollicitudin vitae dolor. Fusce scelerisque eleifend lectus in bibendum. Suspendisse lacinia egestas felis a volutpat.</p> -->
        <div class="bottom-right" @click="openAppStore">
            <div class="gradient-rectangle"></div>
            <p class="more-text">{{ $t('components.place.mainImageSection.more') }}</p>
        </div>
    </div>
    <DownloadAppPage ref="popover"/>
</template>

<script>
import { logAnalyticsEvent } from '@/utils/firebase';
import DownloadAppPage from '@/pages/DownloadAppPage.vue';

export default {
    name: 'AboutView',
    components: {
        DownloadAppPage,
    },
    props: {
        about: String
    },
    methods: {
        openAppStore() {
            logAnalyticsEvent('place_about_more_click');
            this.$refs.popover.openPopover();
        }
    }
}
</script>

<style scoped>
.full-space {
    background: black;
    text-align: left;
    flex-grow: 1;
    display: flex;
    justify-content: left;
    padding-top: 0px;
    margin-top: 0px;
    padding-left: 12px;
    padding-right: 12px;
    color: #C6C6C8;
    font-size: 15px;    
}
.about-text {
   overflow: hidden;
   display: -webkit-box;
   -webkit-line-clamp: 2;
    line-clamp: 2; 
   -webkit-box-orient: vertical;
   height: 46px;
   line-height: 1.5em;
}
.bottom-right {
    position: absolute;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.286) 0%, rgb(0, 0, 0) 100%);
    margin-top: 40px;
    width: 80px;
    height: 20px;
    right: 0;
    display: flex;
    padding-right: 12px;
    cursor: pointer;
}
.gradient-rectangle {
    width: 80px;
    height: 20px;
    background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 100%);
}
.more-text {
    margin: 0px;
    padding: 0px;
    color: white;
    font-weight: bold; 
    font-size: 15px;
}
</style>