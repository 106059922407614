<template>
    <div class="scroll-container">
        <SpecialityView class="scroll-item" v-for="speciality in specialities" :key="speciality._id" :speciality="speciality" />
    </div>
    <hr>
</template>

<script>

import SpecialityView from './SpecialityView.vue'
    export default {
        name: 'SpecialitiesSectionView',
        components: {
            SpecialityView,
        },
        props: {
            specialities: [
                {   
                    _id: String,
                    name: String,
                    image: String,
                }
            ]
        }
    }
</script>

<style scoped>
.scroll-container {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  padding-left: 4px;
  padding-top: 16px;
  padding-right: 4px;
  padding-bottom: 16px;
  scrollbar-width: none; /* for Firefox */
}
.scroll-container::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, etc. */
}
hr {
    margin: 0px;
    border: none;
    height: 1px;
    background-color: #E6E6E7;
}
</style>