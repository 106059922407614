<template>
    <img 
        src = "../../assets/error.svg"
        alt="Error"
    />
    <h1>{{ $t('components.error.text') }}</h1>
    <!-- <ActionButton 
        :title='buttonTitle'
        @click='tapHandler'
    /> -->
</template>
  
<script>
// import ActionButton from './ActionButton.vue';

export default {
    components: {
        // ActionButton
    },
    props: {
        // tapHandler: {
        //     type: Function,
        //     required: true
        // }
    },
    computed: {
        buttonTitle() {
            return this.$t('components.error.buttonTitle')
        }
    }
}
</script>
  
<style scoped>
h1 {
    color: black;
    font-size: 22px;
    font-weight: bold;
    padding-bottom: 60px;
}
img {
    padding-top: 60px;
    width: 281px;
    height: 256px;
}
</style>