<template>
    <ErrorView :tapHandler="reloadPage"/>
 </template>
   
<script>  
import ErrorView from '../components/Shared/ErrorView.vue';
import { configureMetaData } from '@/utils/metaConfigurator';

 export default {
    name: 'ErrorPage',
    components: {
        ErrorView
    },
    props: {
        redirectedFrom: {
            type: String
        }
    },
    methods: {
        reloadPage() {
            window.open('https://apps.apple.com/ru/app/id6451155745', '_blank');
        }
    },
    created() {
        this.id = this.$route.id
        configureMetaData(
            this.$t('components.error.title'),
            this.$t('components.error.text'),
            '../../assets/notFound.svg'
        )
     },
     data() {
       return {
         id: null,
       }
     },
}
</script>
   
<style>
 
</style>