<template>
    <h2>{{ $t('components.place.cuisinesSection.title') }}</h2>
    <div class="cuisines">    
        <div class="cuisine-list">
            <div v-for="(cuisine, index) in cuisines" :key="index" class="cuisine-item">
                <CuisineItemView :cuisine='cuisine' />
            </div>
        </div>
    </div>
    <hr>
</template>

<script>
import CuisineItemView from './CuisineItemView.vue'
export default {
    name: 'CuisinesSectionView',
    components: {
        CuisineItemView,
    },
    props: {
        cuisines: [
            {
                name: String,
                name_ru: String
            }
        ]
    }
}
</script>

<style scoped>
h2 {
    color: black;
    text-align: left;
    padding-left: 16px;
    padding-top: 0px;
    padding-bottom: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
}
hr {
    border: none;
    height: 1px;
    background-color: #E6E6E7;
}
.cuisines {
    padding-left: 12px;
    padding-right: 12px;
}
.cuisine-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.cuisine-item {
  padding: 5px 10px;
  background-color: #e0e0e0;
  border-radius: 15px;
  font-size: 12px;
  font-weight: bold;
  color: black;
}
</style>