<template>
    <h2>{{ $t('components.place.infoSection.title') }}</h2>
    <div class="info-card">
    <div class="info-item">
      <img src = '../../../assets/distanceIcon.png' alt="Location" class="image"/>
      <div class="text-group">
        <span class="title">{{ $t('components.place.infoSection.distance') }}</span>
        <span class="value">{{ formattedDistanceText }}</span>
      </div>
    </div>
    <div v-if="isWorkingToday" class="info-item">
      <img src = '../../../assets/workingTime.png' alt="Working time" class="image"/>
      <div class="text-group">
        <span class="title">{{ $t('components.place.infoSection.workingToday') }}</span>
        <span class="value">{{ formattedWorkingTodayText }}</span>
      </div>
    </div>
  </div>
  <hr>
</template>
<script>
export default {
  name: 'InfoSectionView',
  props: {
    distance: Number,
    workingHours: Object
  },
  computed: {
    formattedDistanceText() {
      return this.formatDistance(this.distance)
    },
    workingHoursToday() {
      let weekDayName = this.getCurrentWeekdayShortName()
      return this.workingHours[weekDayName]
    },
    isWorkingToday() {
      return this.workingHoursToday.start != this.workingHoursToday.end
    },
    formattedWorkingTodayText() {
      return this.workingHoursToday.start + ' - ' + this.workingHoursToday.end
    }
  },
  methods: {
    formatDistance(meters) {
      if (meters > 1000) {
        return (meters/1000).toFixed(2) + ' ' + this.$t('components.place.infoSection.km')
      }
      return meters.toFixed() + ' ' + this.$t('components.place.infoSection.m')
    },
    getCurrentWeekdayShortName() {
      const weekdays = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
      const today = new Date();
      const dayOfWeek = today.getDay(); // Returns a number (0-6) representing the day of the week
 
      return weekdays[dayOfWeek];
    }
   }
}
</script>

<style scoped>
 h2 {
    color: black;
    text-align: left;
    padding-left: 16px;
}
hr {
    border: none;
    height: 1px;
    background-color: #E6E6E7;
}
.info-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}
.image {
    padding-left: 22px;
    padding-right: 6px;
    width: 30px;
    height: 30px;
}
.text-group {
  display: flex;
  flex-direction: column;
}
.title {
    text-align: left;
    font-weight: medium;
    font-size: 14px;
    color: #808085;
}
.value {
    text-align: left;
    font-weight: bold;
    font-size: 15px;
    color: black;
}
</style>