import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '@/pages/HomePage.vue'
import PlacePage from '@/pages/PlacePage.vue'
import ErrorPage from '@/pages/ErrorPage'
import NotFoundPage from '@/pages/NotFoundPage.vue';
// import { configureMetaData } from '@/utils/metaConfigurator';
import { logAnalyticsEvent } from '@/utils/firebase';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage,
  },
  {
    path: '/place',
    name: 'Place',
    component: PlacePage,
  },
  {
    path: '/error',
    name: 'Error',
    component: ErrorPage,
    props: true,
  },
  { 
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFoundPage,
    // meta: {
    //   title: 'Dinao: Where to go in Belgrade',
    //   description: 'Discover the heartbeat of your city with «Dinao», the must-have mobile app for all urban explorers!'
    // }
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  // Set the page title from route meta
  document.title = to.meta.title || 'Dinao';
  document.description = to.meta.description || 'Discover the heartbeat of your city with «Dinao», the must-have mobile app for all urban explorers!';
  next();
})

router.afterEach((to) => {
  // Отправляем событие 'page_view' при каждом изменении маршрута
  logAnalyticsEvent('page_view', {
    page_path: to.fullPath, // Путь страницы
    page_title: to.name || to.path // Название страницы (если задано)
  });
});

export default router;