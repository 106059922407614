<template>
    <div class="image-text-container">
        <img :src = category.icon alt="Dinao" class="image"/>
        <p class="text">{{ localizedNameText }}</p>
    </div>
</template>

<script>
export default {
    name: 'CategoryView',
    props: {
        category: {
            name: String,
            icon: String,
            name_ru: String,
        }
    },
    computed: {
        localizedNameText() {
            switch (this.$i18n.locale) {
                case 'ru':
                    return this.category.name_ru
                default:
                    return this.category.name
            }
        }
    }
}
</script>

<style scoped>
.image-text-container {
  display: flex;
  align-items: center;
  padding-left: 4px;
  padding-right: 4px;
}
.image {
    height: 16px;
    width: 16px;
    filter: brightness(0) invert(1);
    margin-right: 2px;
}
.text {
    color: white;
    font-weight: bold;
    font-size: 12px;
}
</style>