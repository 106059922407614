<template>
    <HomeView/>
</template>

<script>
import HomeView from '@/components/Home/HomeView.vue'
import { configureMetaData } from '@/utils/metaConfigurator';

export default {
  name: 'HomePage',
  components: {
    HomeView
  },
  created() {
    this.id = this.$route.id
    configureMetaData(
        this.$t('components.home.title'),
        this.$t('components.home.text'),
        '@/assets/home.png'
    )
  },
  data() {
    return {
      id: null,
      isLoading: true,
    }
  }
}
</script>
  
<style>

</style>
  