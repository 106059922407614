import { createApp } from 'vue';
import { createHead } from '@vueuse/head'
import App from './App.vue';

import { createI18n } from 'vue-i18n';
import messages from './locales';

import router from './router';

const app = createApp(App);

// Localizations
const getBrowserLanguage = () => {
    const language = navigator.language || navigator.userLanguage;
    return language.split('-')[0]; // Get the base language code (e.g., "en" from "en-US")
};

const i18n = createI18n({
    locale: getBrowserLanguage(), // Set locale based on browser language
    fallbackLocale: 'en', // Fallback language
    messages
});
app.use(i18n);

// Create and install meta manager
const head = createHead()
app.use(head)

// app.config.errorHandler = function (err, vm, info) {
app.config.errorHandler = function (error) {
    console.error('Error:', error);

    // Redirect to the error page
    router.push({ name: 'ErrorPage' });
};

app.use(router);
app.mount('#app');