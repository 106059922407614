Array.prototype.isEmpty = function () {
    return Array.isArray(this) && this.length === 0;
};

Array.prototype.isNotEmpty = function () {
    return Array.isArray(this) && this.length > 0;
};

String.prototype.isNotEmpty = function () {
    return this && this.trim() !== ""
};