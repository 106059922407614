<template>
    <div>
        <p class="text">{{ $t('components.place.lastUpdatedSection.disclaimer') }}</p>
        <p class="last-updated-text">{{ $t('components.place.lastUpdatedSection.lastUpdated') }} {{ formattedDate }}</p>
    </div>
</template>

<script>
import { formatDateString } from '@/utils/dateFormatter';
export default {
        name: 'LastUpdatedSectionView',
        props: {
            updatedAt: String
        },
        computed: {
            formattedDate() {
                return formatDateString(this.updatedAt);
            }
        }
}
</script>

<style scoped>
div {
    padding-left: 12px;
    padding-right: 12px;
}
.text {
    margin-top: 16px;
    margin-bottom: 0px;
    color: #A6A6A6;
    font-size: 12px;
}
.last-updated-text {
    margin-top: 34px;
    margin-bottom: 16px;
    color: #A6A6A6;
    font-size: 12px;
}
</style>