<template>
    {{ localizedNameText }}
</template>

<script>
export default {
    name: 'CuisineItemView',
    props: {
        cuisine: {
            name: String,
            name_ru: String,
        }
    },
    computed: {
        localizedNameText() {
            switch (this.$i18n.locale) {
                case 'ru':
                    return this.cuisine.name_ru
                default:
                    return this.cuisine.name
            }
        }
    }
}
</script>

<style scoped>

</style>