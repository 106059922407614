// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
function getFirebaseConfig() {
  if (process.env.VUE_APP_FIREBASE_CONFIG_ENV === 'firebaseProdConfig') {
    return {
      apiKey: "AIzaSyDqgacugFiepfPJ6seh5QOJy2Gktm1bIdo",
      authDomain: "dinao-web-prod.firebaseapp.com",
      projectId: "dinao-web-prod",
      storageBucket: "dinao-web-prod.appspot.com",
      messagingSenderId: "921556674641",
      appId: "1:921556674641:web:66b8c33b2eaeffa0deb070",
      measurementId: "G-7BDQJ755TB"
    };
  } else {
    return {
      apiKey: "AIzaSyAo-MwjrhpJm_jFxz_d_s21oLadlvY7mms",
      authDomain: "dinao-web-dev.firebaseapp.com",
      projectId: "dinao-web-dev",
      storageBucket: "dinao-web-dev.appspot.com",
      messagingSenderId: "884771058378",
      appId: "1:884771058378:web:2442487c2684d80828ba33",
      measurementId: "G-6CL5735WHR"
    };
  }
}

// Initialize Firebase
const app = initializeApp(getFirebaseConfig());
const analytics = getAnalytics(app);

function logAnalyticsEvent(name, data) {
  logEvent(analytics, name, data)
  // console.log('logAnalyticsEvent:', name, data)
}

export { analytics, logAnalyticsEvent };